.PublikationenWrapper{
    width: 100vw;
    height: auto;
}


.SiteBackground{
    background-image: url('../material/wand.jpg');
    background-repeat: repeat;
    width: 100%;
    height: 150vh;
    border: 1px solid black;
    position: fixed;
    margin-top: -15vh;
    overflow: hidden;
}

.PublikationenBody{
    height: 100vh;
    width: 78%;
    margin-left: 11%;
    margin-top: 5%;
    height: auto;
    display: flex;
    flex-direction: column;
    position: relative;
}

.PublikationenBody_Mobile{
    height: 100vh;
    width: 95%;
    margin-left: 3%;
    margin-top: 20%;
    height: auto;
    display: flex;
    flex-direction: column;
    position: relative;
}

.PubScreen{
    background-color: rgba(255, 255, 255, 0.815);
    width: 90%;
    height: auto;
    margin-left: 5%;
    margin-top: 5vh;
    box-shadow: 1px 1px 10px 1px black;
    display: flex;
    flex-direction: column;

}

.PubScreenTitel1{
    font-size: 2vw;
    font-weight: 500;
    margin-left: 1%;
    margin-top: 1%;
    width: 100%;
    text-align: center;
}

.PubScreenTitel1_Mobile{
    font-size: 8vw;
    font-weight: 500;
    margin-left: 1%;
    margin-top: 1%;
    width: 100%;
    text-align: center;
}

.PubScreenTitel2{
    font-size: 1.8vw;
    font-weight: 500;
    margin-left: 2%;
    margin-top: 2%;
    color: rgb(71, 71, 72);
}

.PubScreenTitel2_Mobile{
    font-size: 5.5vw;
    font-weight: 500;
    margin-left: 2%;
    margin-top: 2%;
    color: rgb(71, 71, 72);
}

.PubRow{
    width: 95%;
    margin-left: 2%;
    margin-top: 1%;
    height: auto;
    margin-bottom: 1%;
    display: flex;
    flex-direction: row;
}

.PubRowBullet{
    font-size: 5vw;
    width: 5%;
    text-align: center;
    margin-top: -6.5%;
}

.PubRowBullet_Mobile{
    font-size: 10vw;
    width: 5%;
    text-align: center;
    margin-top: -10%;
}

.PubRowTitel{
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    width: 82%;
    font-size: 1.2vw;
}

.PubRowTitel_Mobile{
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    width: 82%;
    font-size: 3.2vw;
}

.PubRowLinkArea{

    width: 12%;
    margin-left: 1%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.RG_Link{
    background-image: url('../material/RG_passive.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 2.5vw;
    height: 2.5vw;
    overflow: hidden;
    margin-top: 10%;
    transition: 1s;
    margin-top: 30%;
}

.RG_Link_Mobile{
    background-image: url('../material/RG_passive.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 5.5vw;
    height: 3.5vw;
    overflow: hidden;
    margin-top: 10%;
    transition: 1s;
    margin-top: 30%;
}

.RG_Link_Mobile:hover{
    background-image: url('../material/RG.jpeg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 5.5vw;
    height: 3.5vw;
    overflow: hidden;
    margin-top: 10%;
    transition: 1s;
    margin-top: 30%;
}

.RG_Link:hover{
    background-image: url('../material/RG.jpeg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 2.5vw;
    height: 2.5vw;
    overflow: hidden;
    margin-top: 10%;
    cursor: pointer;
    transition: 1s;
    margin-top: 30%;
}

.SLLD_Link{
    background-image: url('../material/SLLD_passive.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 5.5vw;
    height: 2.5vw;
    overflow: hidden;
    margin-top: 10%;
    transition: 1s;
    margin-top: 10%;
}

.SLLD_Link_Mobile{
    background-image: url('../material/SLLD_passive.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 9.5vw;
    height: 3.5vw;
    overflow: hidden;
    margin-top: 10%;
    transition: 1s;
    margin-top: 10%;
}

.SLLD_Link_Mobile:hover{
    background-image: url('../material/SLLD.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 9.5vw;
    height: 3.5vw;
    overflow: hidden;
    margin-top: 10%;
    transition: 1s;
    margin-top: 10%;
}

.SLLD_Link:hover{
    background-image: url('../material/SLLD.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 5.5vw;
    height: 2.5vw;
    overflow: hidden;
    margin-top: 10%;
    transition: 1s;
    margin-top: 10%;
    cursor: pointer;
}

.Springer_Link{
    background-image: url('../material/Springer_passiv.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 5.5vw;
    height: 2.5vw;
    overflow: hidden;
    margin-top: 10%;
    transition: 1s;
    margin-top: 10%;
}

.Springer_Link_Mobile{
    background-image: url('../material/Springer_passiv.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 9.5vw;
    height: 2.5vw;
    overflow: hidden;
    margin-top: 10%;
    transition: 1s;
    margin-top: 10%;
}

.Springer_Link:hover{
    background-image: url('../material/Springer.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 5.5vw;
    height: 2.5vw;
    overflow: hidden;
    margin-top: 10%;
    transition: 1s;
    margin-top: 10%;
    cursor: pointer;
}

.Springer_Link_Mobile:hover{
    background-image: url('../material/Springer.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 9.5vw;
    height: 2.5vw;
    overflow: hidden;
    margin-top: 10%;
    transition: 1s;
    margin-top: 10%;
}

.MedienPaed_Link{
    background-image: url('../material/Medienpaed_passiv.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 5.5vw;
    height: 2.5vw;
    overflow: hidden;
    margin-top: 10%;
    transition: 1s;
    margin-top: 10%;
}

.MedienPaed_Link:hover{
    background-image: url('../material/Medienpaed.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 5.5vw;
    height: 2.5vw;
    overflow: hidden;
    margin-top: 10%;
    transition: 1s;
    margin-top: 10%;
    cursor: pointer;
}

.MedienPaed_Link_Mobile{
    background-image: url('../material/Medienpaed_passiv.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 9.5vw;
    height: 2.5vw;
    overflow: hidden;
    margin-top: 10%;
    transition: 1s;
    margin-top: 10%;
}

.KuJM_Link{
    background-image: url('../material/KinderundJugendMedien_passiv.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 5.5vw;
    height: 2.5vw;
    overflow: hidden;
    margin-top: 10%;
    transition: 1s;
    margin-top: 10%;
}

.KuJM_Link:hover{
    background-image: url('../material/KinderundJugendMedien.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 5.5vw;
    height: 2.5vw;
    overflow: hidden;
    margin-top: 10%;
    transition: 1s;
    margin-top: 10%;
    cursor: pointer;
}

.KuJM_Link_Mobile{
    background-image: url('../material/KinderundJugendMedien_passiv.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 9.5vw;
    height: 2.5vw;
    overflow: hidden;
    margin-top: 10%;
    transition: 1s;
    margin-top: 10%;
}

.sciendo_Link{
    background-image: url('../material/sciendo_passiv.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 5.5vw;
    height: 2.5vw;
    overflow: hidden;
    margin-top: 10%;
    transition: 1s;
    margin-top: 10%;
}

.sciendo_Link:hover{
    background-image: url('../material/sciendo.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 5.5vw;
    height: 2.5vw;
    overflow: hidden;
    margin-top: 10%;
    transition: 1s;
    margin-top: 10%;
}

.sciendo_Link_Mobile{
    background-image: url('../material/sciendo_passiv.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 9.5vw;
    height: 2.5vw;
    overflow: hidden;
    margin-top: 10%;
    transition: 1s;
    margin-top: 10%;
}

.TaylorFrancisLink{
    background-image: url('../material/Taylor_and_Francis_passiv.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 5.5vw;
    height: 2.5vw;
    overflow: hidden;
    margin-top: 10%;
    transition: 1s;
    margin-top: 10%;
    margin-left: 25%;
}

.TaylorFrancisLink:hover{
    background-image: url('../material/Taylor_and_Francis.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 5.5vw;
    height: 2.5vw;
    overflow: hidden;
    margin-top: 10%;
    transition: 1s;
    margin-top: 10%;
    margin-left: 25%;
}

.TaylorFrancisLink_Mobile{
    background-image: url('../material/Taylor_and_Francis_passiv.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 9.5vw;
    height: 2.5vw;
    overflow: hidden;
    margin-top: 10%;
    transition: 1s;
    margin-top: 10%;
    margin-left: 25%;
}

.FriedrichLink{
    background-image: url('../material/fv_logo_passiv.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 5.5vw;
    height: 2.5vw;
    overflow: hidden;
    margin-top: 10%;
    transition: 1s;
    margin-top: 10%;
    margin-left: 25%;
}

.FriedrichLink:hover{
    background-image: url('../material/fv_logo.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 5.5vw;
    height: 2.5vw;
    overflow: hidden;
    margin-top: 10%;
    transition: 1s;
    margin-top: 10%;
    margin-left: 25%;
}

.FriedrichLink_Mobile{
    background-image: url('../material/fv_logo_passiv.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 9.5vw;
    height: 2.5vw;
    overflow: hidden;
    margin-top: 10%;
    transition: 1s;
    margin-top: 10%;
    margin-left: 25%;
}

.JOWRLink{
    background-image: url('../material/Jowr_passiv.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 5.5vw;
    height: 3.0vw;
    overflow: hidden;
    margin-top: 10%;
    transition: 1s;
    margin-top: 10%;
    margin-left: 25%;
}

.JOWRLink_Mobile{
    background-image: url('../material/Jowr_passiv.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 9.5vw;
    height: 2.5vw;
    overflow: hidden;
    margin-top: 10%;
    transition: 1s;
    margin-top: 10%;
    margin-left: 25%;
}

.JOWRLink:hover{
    background-image: url('../material/Jowr_aktiv.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 5.5vw;
    height: 3.0vw;
    overflow: hidden;
    margin-top: 10%;
    transition: 1s;
    margin-top: 10%;
    margin-left: 25%;
}